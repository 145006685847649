import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CustomButton from 'components/UIKit/CustomButton';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		margin: '30px',
		backgroundColor: theme.palette.grey.dark,
		borderRadius: '12px',
		minWidth: '382px',
		width: 'min-content',
		height: 'min-content',
		padding: '10px 16px',
		boxShadow: '2px 5px 3px rgba(0,0,0,0.4)',
		'& .MuiTypography-h6': {
			fontWeight: '600',
		},
	},
	minHeight: {
		minHeight: '200px',
	},
	maxWidth: {
		width: 'max-content',
	},
	expanded: {
		minWidth: '450px',
	},
	successContainer: {
		textAlign: 'center',
		marginTop: '10px',
		'& .MuiSvgIcon-root': {
			marginTop: '10px',
		},
	},
	successButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		marginTop: '10px',
	},
	bgBlur: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		zIndex: '500',
		backgroundColor: 'rgba(255, 255, 255, 0.40)',
		backdropFilter: 'blur(4px)',
	},
}));

export default function Section(props) {
	const classes = useStyles();
	const [showSuccessMsg, setShowSuccessMsg] = useState(false);

	const classNames = clsx({
		[classes.container]: true,
		[classes.maxWidth]: props.maxWidth, // make the section container width match that of the content width
		[classes.minHeight]: props.successMsg,
		[classes.expanded]: props.expanded,
	});

	useEffect(() => {
		if (props.successMsg) {
			setShowSuccessMsg(true);
		} else if (!props.successMsg) {
			setShowSuccessMsg(false);
		}
	}, [props.successMsg]);

	const handleClick = () => {
		setShowSuccessMsg(false);
	};

	return (
		<Box className={classNames} name={props.name}>
			<Typography variant='h6'>{props.title}</Typography>
			{showSuccessMsg && (
				<>
					<Box className={classes.successContainer}>
						<Typography variant='body2'>{props.successText}</Typography>
						<CheckCircleOutlineIcon fontSize='large' />
					</Box>
					{props.successBtnText && (
						<Box className={classes.successButton}>
							<CustomButton variant='secondary' size='small' onClick={handleClick}>
								{props.successBtnText}
							</CustomButton>
						</Box>
					)}
				</>
			)}
			{!showSuccessMsg && props.children}
			{props.blur && (
				<Box className={classes.bgBlur}>
					<Box>{props.infoMsg}</Box>
				</Box>
			)}
		</Box>
	);
}
