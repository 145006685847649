// lib
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// components
import CustomButton from 'components/UIKit/CustomButton';
import { guestLogin } from 'redux/actions/auth';

const useStyles = makeStyles((theme) => ({
	buttonGroup: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		marginBottom: '40%',
	},
}));

export default function GuestAndSignUpButtons() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const kiosk = useSelector((state) => state.admin.kiosk);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const signUpPage = window.location.toString().includes('/sign-up');

	const guestSignIn = () => {
		setIsButtonDisabled(true);
		dispatch(guestLogin()).finally(() => {
			setIsButtonDisabled(false);
		});
	};

	const handleNavigation = () => {
		history.push(signUpPage ? '/sign-in' : '/sign-up');
	};

	return (
		<div className={classes.buttonGroup}>
			{kiosk && (
				<CustomButton size='extra-large' disabled={isButtonDisabled} onClick={guestSignIn}>
					<Typography variant='subtitle1'>{t('common.buttons.signInAs')}</Typography>
					<Typography variant='h5'>{t('common.buttons.guest')}</Typography>
				</CustomButton>
			)}
			<CustomButton name='data-cy-signup' size='extra-large' onClick={handleNavigation}>
				{!signUpPage && (
					<>
						<Typography variant='subtitle1'>{t('common.buttons.notAMember')}</Typography>
						<Typography variant='h5'>{t('common.buttons.signUp')}</Typography>
					</>
				)}
				{signUpPage && (
					<>
						<Typography variant='subtitle1'>{t('common.buttons.alreadyAMember')}</Typography>
						<Typography variant='h5'>{t('common.buttons.login')}</Typography>
					</>
				)}
			</CustomButton>
		</div>
	);
}
