export const materialsList = [
	{
		short: 'PETE',
		full: 'PETE',
		alternate: 'pete',
	},
	{
		short: 'HDPE',
		full: 'HDPE',
		alternate: 'hdpe',
	},
	{
		short: 'PVC',
		full: 'PVC',
		alternate: 'pvc',
	},
	{
		short: 'LDPE',
		full: 'LDPE',
		alternate: 'ldpe',
	},
	{
		short: 'PP',
		full: 'PP',
		alternate: 'pp',
	},
	{
		short: 'PS',
		full: 'PS',
		alternate: 'ps',
	},
	{
		short: 'OTHER',
		full: 'OTHER',
		alternate: 'other',
	},
	{
		short: 'ALU',
		full: 'Aluminum',
		alternate: 'aluminum',
	},
	{
		short: 'BMT',
		full: 'Bi-Metal',
		alternate: 'bi-metal',
	},
	{
		short: 'GLS',
		full: 'Glass',
		alternate: 'glass',
	},
	{
		short: 'BIB',
		full: 'Bag-in-Box',
		alternate: 'bag-in-box',
	},
	{
		short: 'MLP',
		full: 'Multilayer-Pouch',
		alternate: 'multilayer-pouch',
	},
	{
		short: 'PBC',
		full: 'Paperboard-Carton',
		alternate: 'paperboard-carton',
	},
];
