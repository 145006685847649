import React from 'react';
import { useTranslation } from 'react-i18next';

// mui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// components
import MaterialPanel from 'components/UIKit/MaterialPanel';
import CountInputs from 'components/CountsAndWeight/CountInputs';
import WeightInputs from 'components/CountsAndWeight/WeightInputs';
import ScrapInputs from 'components/CountsAndWeight/ScrapInputs';
import CustomButton from 'components/UIKit/CustomButton';
import PrevWeightEntry from 'components/UIKit/PrevWeightEntry';
import EditEcoCountScrapInputs from 'components/CountsAndWeight/EditEcoCountScrapInputs';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'& button': {
			marginTop: '40px',
		},
	},
	prevEntry: {
		position: 'absolute',
		top: '4px',
		left: '10px',
		display: 'flex',
	},
	badge: {
		borderRadius: '50px',
		padding: '0px 16px 2px 16px',
		textAlign: 'center',
		marginLeft: '10px',
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.secondary.main,
	},
}));

export default function Editor({
	materials,
	materialToEdit,
	showEditCount,
	showEditWeight,
	showEditScrap,
	showEditEcoCountScrap,
	prevWeightEntry,
	prevScrapEntry,
	handleGrossWeight,
	handleTareWeight,
	unformattedPrevEntry,
	handleCloseEdit,
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.container}>
			{materials.map(
				(material, index) =>
					material.type === materialToEdit && (
						<MaterialPanel name={material.type} fullName={material.displayName} key={index}>
							{showEditCount && <CountInputs material={material} show={true} />}
							{showEditWeight && (
								<>
									<PrevWeightEntry
										show={showEditWeight}
										gross={prevWeightEntry.gross}
										tare={prevWeightEntry.tare}
									/>
									<WeightInputs
										show={true}
										handleCloseEdit={handleCloseEdit}
										editor={true}
										prevWeightEntry={prevWeightEntry}
										materialType={material.type}
										handleGrossWeight={handleGrossWeight}
										handleTareWeight={handleTareWeight}
									/>
								</>
							)}
							{showEditScrap && (
								<>
									<Box className={classes.prevEntry}>
										<Typography variant='body2'>
											{t('overlay.review.prevEntry')}:
										</Typography>
										<Box className={classes.badge}>
											<Typography variant='caption'>
												{prevScrapEntry.split(',')[0]}
											</Typography>
										</Box>
										<Box className={classes.badge}>
											<Typography variant='caption'>
												{prevScrapEntry.split(',')[1]}
											</Typography>
										</Box>
										{prevScrapEntry.split(',')[2] && (
											<Box className={classes.badge}>
												<Typography variant='caption'>
													{prevScrapEntry.split(',')[2]}
												</Typography>
											</Box>
										)}
									</Box>
									<ScrapInputs
										show={true}
										review
										doneEdit={handleCloseEdit}
										materialType={material.type}
									/>
								</>
							)}
						</MaterialPanel>
					),
			)}
			{showEditEcoCountScrap && (
				<>
					{materials.map(
						(material, index) =>
							material.altType === materialToEdit && (
								<MaterialPanel
									name={material.type}
									fullName={material.displayName}
									key={index}
								>
									<EditEcoCountScrapInputs
										doneEdit={handleCloseEdit}
										materialType={materialToEdit}
										prevEntry={unformattedPrevEntry}
									/>
								</MaterialPanel>
							),
					)}
				</>
			)}
			{!showEditScrap && !showEditEcoCountScrap && !showEditWeight && (
				<CustomButton onClick={handleCloseEdit}>{t('common.buttons.continue')}</CustomButton>
			)}
		</Box>
	);
}
