import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	prevEntry: {
		position: 'absolute',
		top: '4px',
		left: '10px',
		display: 'flex',
	},
	badge: {
		borderRadius: '50px',
		padding: '0px 16px 2px 16px',
		textAlign: 'center',
		marginLeft: '10px',
		color: theme.palette.text.secondary,
		backgroundColor: theme.palette.secondary.main,
	},
}));

export default function PrevWeightEntry({ show, gross, tare }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<>
			{show && (
				<Box className={classes.prevEntry}>
					<Typography variant='body2'>{t('overlay.review.prevEntry')}:</Typography>
					<Box className={classes.badge}>
						<Typography variant='caption'>
							{t('overlay.review.grosss')} {gross} lbs
						</Typography>
					</Box>
					<Box className={classes.badge}>
						<Typography variant='caption'>
							{t('overlay.review.taree')} {tare} lbs
						</Typography>
					</Box>
				</Box>
			)}
		</>
	);
}
