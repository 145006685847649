import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { SERVER_URL } from 'redux/actions/config';
import {
	SET_BELT_ID,
	SET_ADMIN_AUTH,
	SET_SECRET,
	SET_SOFTWARE_VERSION,
	SET_MACHINE_DATA,
	SET_KIOSK_MODE,
	SET_EXPRESS_MODE,
} from 'redux/actions/types';
import { configHeader, employeeConfigHeader } from 'utils/configHeader';

export const checkMachineCredentials = (id, secret) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const USState = getState().admin.state;
		const expressStatus = getState().admin.express;
		const payload = {
			belt_id: id,
			secret,
		};
		const body = JSON.stringify(payload);
		const config = employeeConfigHeader();

		axios
			.post(`${SERVER_URL}/task/check_machine_credentials`, body, config)
			.then((res) => {
				const data = res.data;

				// TODO: refactor set_belt_id and set_secret into one action
				dispatch({
					type: SET_BELT_ID,
					payload: id,
				});
				dispatch({
					type: SET_SECRET,
					payload: secret,
				});
				dispatch({
					type: SET_MACHINE_DATA,
					payload: {
						certId: data.cert_id,
						location: data.location,
						name: data.name,
						owner: data.owner,
						state: USState || data.state,
						url: data.url,
						tenantShortcode: data.tenant_shortcode,
					},
				});
				dispatch({
					type: SET_KIOSK_MODE,
					payload: data.url.includes('virtual') ? false : true,
				});
				dispatch({
					type: SET_EXPRESS_MODE,
					payload: data.url.includes('virtual') ? true : expressStatus,
				});

				Sentry.setTag('machine.state', data.state);
				Sentry.setTag('machine.name', data.name);
				Sentry.setTag('machine.owner', data.owner);

				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response?.data?.message;

				reject(errorInfo ? errorInfo : 'Error setting machine ID and/or secret');
			});
	});
};

export const getSoftwareVersion = () => (dispatch, getState) => {
	const config = configHeader();

	axios
		.get(`${SERVER_URL}/version/`, config)
		.then((res) => {
			dispatch({
				type: SET_SOFTWARE_VERSION,
				payload: res.data,
			});
		})
		.catch((err) => {
			console.log('Error getting software version:', err);
		});
};

export const logoutAdmin = () => (dispatch) => {
	dispatch({
		type: SET_ADMIN_AUTH,
		payload: false,
	});
};

export const employeeLogin = (email, password) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();
		const payload = { email, password };
		const body = JSON.stringify(payload);

		axios.post(`${SERVER_URL}/employee_login`, body, config).then((res) => {});
	});
};
