// lib
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

// mui
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// components
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import CustomButton from 'components/UIKit/CustomButton';
import AdminInput from 'components/Admin/AdminInput';
import PageHeader from 'components/Admin/PageHeader';
import Section from 'components/Admin/Section';
import ScrollContainer from 'components/UIKit/ScrollContainer';
import {
	SET_MATERIAL_TYPE,
	SET_USA_STATE,
	SET_EXPRESS_MODE,
	SET_KIOSK_MODE,
	SET_ECOCARD_SETUP_ENABLED,
	SET_REMOTE_PRINTER,
	SET_EXPRESS_CASH_ENABLED,
} from 'redux/actions/types';
import { checkMachineCredentials } from 'redux/actions/admin';
import { employeeLogin } from 'redux/actions/auth';

// utils/lib
import { machineLocations } from 'lib/machineLocations.js';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		maxHeight: '80vh',
		overflowY: 'scroll',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	inputContainer: {
		width: '350px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .MuiTypography-root': {
			marginTop: '8px',
		},
	},
	input: {
		maxWidth: '200px',
		align: 'left',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px',
	},
	expanded: {
		'& .MuiInputBase-root': {
			width: '200px',
		},
	},
}));

export default function Settings() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const process = useSelector((state) => state.process);
	const materialSettings = useSelector((state) => state.admin.materialType);
	const express = useSelector((state) => state.admin.express);
	const kiosk = useSelector((state) => state.admin.kiosk);
	const employee = useSelector((state) => state.auth.employee);
	const expressCash = useSelector((state) => state.admin.expressCashEnabled);
	const prevLocation = useSelector((state) => state.admin.prevLocation);
	const language = useSelector((state) => state.auth.language);
	const url = useSelector((state) => state.admin.url);
	const ecoCardSetupEnabled = useSelector((state) => state.admin.ecoCardSetupEnabled);
	const remotePrinter = useSelector((state) => state.admin.remotePrinter);
	const savedMachineLocation = useSelector((state) => state.admin.state);
	const [beltId, setBeltId] = useState('');
	const [secret, setSecret] = useState('');
	const [machineLocation, setMachineLocation] = useState('');
	const [employeeEmail, setEmployeeEmail] = useState('');
	const [employeePassword, setEmployeePassword] = useState('');
	const [message, setMessage] = useState({ text: '', status: '' });
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [materials, setMaterials] = useState('');

	useEffect(() => {
		if (materialSettings) setMaterials(materialSettings);
	}, []);

	const handleBeltIdSubmit = () => {
		setIsButtonDisabled(true);
		dispatch(checkMachineCredentials(beltId, secret))
			.then(() => {
				setMessage({ text: t('admin.beltIdAndMachineSaved'), status: 'success' });
			})
			.catch((err) => {
				setMessage({ text: err, status: 'error' });
			})
			.finally(() => {
				setIsButtonDisabled(false);
			});
	};

	const handleEmployeeLogin = () => {
		setIsButtonDisabled(true);
		dispatch(employeeLogin(employeeEmail, employeePassword))
			.then(() => {
				setMessage({ text: t('admin.employeeLoginSuccess'), status: 'success' });
			})
			.catch(() => {
				setMessage({ text: t('admin.invalidCredentials'), status: 'error' });
			})
			.finally(() => {
				setIsButtonDisabled(false);
			});
	};

	// adds or removes material selected if it is checked or not
	const handleChange = (e) => {
		if (e.checked) {
			setMaterials((prevState) => [...prevState, e.value]);
		} else if (!e.checked) {
			const removeMaterial = materials.filter((selectedMaterial) => {
				return selectedMaterial !== e.value;
			});
			setMaterials(removeMaterial);
		}
	};

	const handleChangeExpress = (e) => {
		dispatch({
			type: SET_EXPRESS_MODE,
			payload: e.checked,
		});
	};

	const handleChangeRemotePrinter = (e) => {
		dispatch({
			type: SET_REMOTE_PRINTER,
			payload: e.checked,
		});
	};

	const handleEcoCardChange = (e) => {
		dispatch({
			type: SET_ECOCARD_SETUP_ENABLED,
			payload: e.checked,
		});
	};

	const handleChangeExpressCash = (e) => {
		dispatch({
			type: SET_EXPRESS_CASH_ENABLED,
			payload: e.checked,
		});
	};

	const handleMaterialSubmit = () => {
		if (materials) {
			dispatch({
				type: SET_MATERIAL_TYPE,
				payload: materials,
			});
			setMessage({ text: t('admin.materialSettingsSaved'), status: 'success' });
		}
	};

	const handleSubmitMachineLocation = () => {
		dispatch({
			type: SET_USA_STATE,
			payload: machineLocation,
		});
		setMessage({ text: t('admin.machineLocationSaved'), status: 'success' });
	};

	const handleChangeKioskMode = (e) => {
		dispatch({
			type: SET_KIOSK_MODE,
			payload: e.checked,
		});
	};

	const closeSnackbar = () => {
		setMessage({ message: '', status: '' });
	};

	return (
		<>
			<CustomSnackbar message={message.text} status={message.status} close={closeSnackbar} />
			<PageHeader title={t('admin.generalSettings')} />

			<Box className={classes.container}>
				<ScrollContainer>
					<Grid container>
						<Grid item>
							<Section
								title={t('admin.selectMaterialTypes')}
								successMsg={materialSettings}
								successText={t('admin.materialsSetup')}
								successBtnText={t('admin.changeSettings')}
							>
								<AdminInput
									label={t('admin.plastic')}
									type='switch'
									value='plastic'
									checked={materials.includes('plastic')}
									handleChange={(e) => handleChange(e.target)}
								/>
								<AdminInput
									label={t('admin.aluminum')}
									type='switch'
									checked={materials.includes('aluminum')}
									value='aluminum'
									handleChange={(e) => handleChange(e.target)}
								/>
								<AdminInput
									label={t('admin.otherMaterials')}
									type='switch'
									checked={materials.includes('other')}
									value='other'
									handleChange={(e) => handleChange(e.target)}
								/>
								<Box className={classes.buttonContainer}>
									<CustomButton
										variant='secondary'
										size='small'
										name='cy-settings-material'
										onClick={handleMaterialSubmit}
									>
										{t('admin.acceptAndSave')}
									</CustomButton>
								</Box>
							</Section>
							<Section title={t('admin.enableEcoCardSetup')}>
								<AdminInput
									label={t('admin.ecoCardSetup')}
									type='switch'
									value='card'
									checked={ecoCardSetupEnabled}
									handleChange={(e) => handleEcoCardChange(e.target)}
								/>
							</Section>
							<Section title={t('admin.expressMode')}>
								<AdminInput
									label={t('admin.enableExpressMode')}
									type='switch'
									size='small'
									checked={express}
									value='express'
									handleChange={(e) => handleChangeExpress(e.target)}
								/>
								<AdminInput
									label={t('admin.enableRemotePrinter')}
									type='switch'
									size='small'
									checked={remotePrinter}
									value='remote-printer'
									handleChange={(e) => handleChangeRemotePrinter(e.target)}
								/>
								<AdminInput
									label={t('admin.enableCashPayouts')}
									type='switch'
									size='small'
									checked={expressCash}
									value='express-cash'
									handleChange={(e) => handleChangeExpressCash(e.target)}
								/>
							</Section>
						</Grid>
						<Grid item>
							<Section
								title={t('admin.connectToAMachine')}
								successMsg={process.machine.belt_id && process.machine.secret}
								successText={t('admin.beltIdSecretSuccess')}
								successBtnText={t('admin.changeSettings')}
							>
								<>
									<AdminInput
										label={t('admin.machineBeltId')}
										value={beltId}
										name='cy-machine-belt-id'
										handleChange={(e) => setBeltId(e.target.value)}
									/>
									<AdminInput
										label={t('admin.machineSecret')}
										value={secret}
										isPassword={true}
										name='cy-machine-secret'
										handleChange={(e) => setSecret(e.target.value)}
									/>
									<Box className={classes.buttonContainer}>
										<CustomButton
											variant='secondary'
											size='small'
											name='cy-settings-belt'
											disabled={isButtonDisabled}
											onClick={handleBeltIdSubmit}
										>
											{t('admin.acceptAndSave')}
										</CustomButton>
									</Box>
								</>
							</Section>
							<Section
								title={t('admin.employeeLogin')}
								successMsg={employee.token}
								successText={t('admin.employeeLoggedInMsg', {
									username: employee.username,
								})}
								successBtnText={t('admin.changeSettings')}
							>
								<Box className={classes.expanded}>
									<AdminInput
										label={t('admin.email')}
										value={employeeEmail}
										name='cy-employee-email'
										handleChange={(e) => setEmployeeEmail(e.target.value)}
									/>
									<AdminInput
										label={t('admin.password')}
										value={employeePassword}
										isPassword={true}
										name='cy-employee-password'
										handleChange={(e) => setEmployeePassword(e.target.value)}
									/>
									<Box className={classes.buttonContainer}>
										<CustomButton
											variant='secondary'
											size='small'
											name='cy-employee-login'
											disabled={isButtonDisabled}
											onClick={handleEmployeeLogin}
										>
											{t('admin.acceptAndSave')}
										</CustomButton>
									</Box>
								</Box>
							</Section>
							<Section
								name='data-cy-settings-location'
								title={t('admin.selectMachineLocation')}
								successMsg={savedMachineLocation}
								successText={`${t(
									'admin.machineLocationSetup',
								)} (${savedMachineLocation}). ${t('admin.clickChange')}`}
								successBtnText={t('admin.changeSettings')}
							>
								<div name='data-cy-machine-location-select'>
									<AdminInput
										label={t('admin.selectState')}
										type='select'
										selectData={machineLocations}
										handleChange={(e) => setMachineLocation(e.target.value)}
									/>
								</div>
								<Box className={classes.buttonContainer}>
									<CustomButton
										variant='secondary'
										size='small'
										name='data-cy-settings-location-save'
										onClick={handleSubmitMachineLocation}
									>
										{t('admin.acceptAndSave')}
									</CustomButton>
								</Box>
							</Section>
							<Section title={t('admin.kioskMode')}>
								<AdminInput
									label={t('admin.enableKioskMode')}
									type='switch'
									size='small'
									checked={kiosk}
									value='kiosk-mode'
									unavailable={url.includes('virtual')}
									handleChange={(e) => handleChangeKioskMode(e.target)}
								/>
							</Section>
						</Grid>
					</Grid>
				</ScrollContainer>
			</Box>
		</>
	);
}
