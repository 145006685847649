import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Box, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	logoutButton: {
		textTransform: 'unset',
		cursor: 'pointer',
	},
	icon: {
		backgroundColor: '#97D700',
		borderRadius: '33px',
		padding: '4px',
		marginRight: '8px',
		fontSize: '28px',
	},
}));

export default function LogoutButton({ logout }) {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const prevTaskId = useSelector((state) => state.process.prevTaskId);
	const transactionFinalized = useSelector((state) => state.process.finalized);
	const taskId = useSelector((state) => state.process.task_id?.task_id);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		isDisabled();
	}, [taskId, prevTaskId]);

	const isDisabled = () => {
		if ((taskId || prevTaskId) && !transactionFinalized) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	};

	return (
		<Box>
			<Button className={classes.logoutButton} disabled={disabled} onClick={logout}>
				<ArrowBackIcon className={classes.icon} />
				<Typography variant='body1'>{t('common.buttons.logout')}</Typography>
			</Button>
		</Box>
	);
}
